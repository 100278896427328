.configLandingPageOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;

    .configLandingPageModal {
        width: 98%;
        height: 95%;
        background-color: white;
        border-radius: 15px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-flow: column;

        &__header {
            display: flex;
            width: 100%;
            align-items: flex-start;
            justify-content: center;
            padding: 25px;
            flex-flow: column;
            border-bottom: 1px solid #E4E9F2;

            h1 {
                font-family: "Source Sans Pro", sans-serif;
                font-style: normal;
                font-size: 22px;
                font-weight: 600;
                color: #0054A6 !important;
                margin: 0;
                padding: 0;
                text-transform: uppercase;
            }

            h2 {
                font-family: "Source Sans Pro", sans-serif;
                font-style: normal;
                font-size: 18px;
                font-weight: 600;
                color: #2A333E;
                margin: 0;
            }

        }

        &__content {
            display: flex;
            width: 100%;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 25px;
            flex: 1;
            flex-flow: column;
            overflow: hidden;
            overflow-y: auto;

            &__imageInputs {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .quill {
                width: 100% !important;
                height: auto !important;
                max-height: 320px !important;
            }

            .image-item {
                display: flex;
                margin: 10px 0;
            }

            .image-item__btn-wrapper {
                display: flex;
                flex-direction: column;
                margin-left: 10px;
            }

            button {
                height: 40px;
                padding: 12px;
            }
        }

        &__footer {
            width: 100%;
            padding: 25px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}